<template>
    <div class="newsDetails_page">
        <Banner :adCategory="5"></Banner>
        <div class="breadcrumb_box flex-center">
            <Breadcrumb :breadcrumbList="breadcrumbList"></Breadcrumb>
        </div>
        <div class="main content flex">
            <div class="richText_box">
                <div class="title">{{ formData.newsTitle }}</div>
                <div class="postedTime_viewNum flex-center">
                    <div class="postedTime">发表时间：{{ formData.createTime }}</div>
                    <div class="viewNum">浏览量：{{ formData.browseNum }}</div>
                </div>
                <div class="richText" v-html="formData.content"></div>
            </div>
            <div class="recommend">
                <div class="recommendNews">
                    推荐新闻
                    <div class="line"></div>
                </div>
                <div class="recommendList pointer" v-for="(item, index) in newsList" :key="index"
                    @click="detailsBtn(item)">
                    <div style="overflow: hidden">
                        <img class="icon_news" :src="item.newsImgUrl" alt="" />
                    </div>
                    <div class="newsName astrict">
                        {{ item.newsTitle }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Banner from '@/components/banner'
import Breadcrumb from '@/components/breadcrumb'
export default {
    name: 'newsDetails',
    components: {
        Banner,
        Breadcrumb,
    },
    data() {
        return {
            breadcrumbList: [
                {
                    name: '新闻资讯',
                    url: '',
                },
            ],
            formData: {},
            newsList: [],
            params: {
                id: '',
            },
        }
    },
    created() {
        let fatherName = ''
        let fatherId = this.$route.query.fatherId
        if (fatherId == 1) {
            fatherName = '公司新闻'
        } else {
            fatherName = '行业资讯'
        }
        this.breadcrumbList = [
            {
                name: '新闻资讯',
                url: '',
            },
            {
                name: fatherName,
                url: '',
            },
            {
                name: this.$route.query.name,
                url: '',
            },
        ]
    },
    mounted() {
        this.params.id = this.$route.query.id
        this.getData()
        this.getRecommendData()
    },
    methods: {
        getData() {
            this.api.findNewsDetails(this.params).then((res) => {
                res.data.createTime = res.data.createTime.slice(0, 10)
                this.formData = res.data
            })
        },
        //获取推荐新闻
        getRecommendData() {
            this.api
                .findRecommendNews({ newsType: this.$route.query.fatherId })
                .then((res) => {
                    let arr = []
                    res.data.forEach((item) => {
                        if (item.id != this.$route.query.id) {
                            arr.push(item)
                        }
                    })
                    this.newsList = arr.slice(1, 4)
                })
        },
        detailsBtn(item) {
            console.log(item)
            let fatherName = ''
            let fatherId = this.$route.query.fatherId
            if (fatherId == 1) {
                fatherName = '公司新闻'
            } else {
                fatherName = '行业资讯'
            }
            this.breadcrumbList = [
                {
                    name: '新闻资讯',
                    url: '',
                },
                {
                    name: fatherName,
                    url: '',
                },
                {
                    name: item.newsTitle,
                    url: '',
                },
            ]
            this.params.id = item.id
            this.getData()
            this.getRecommendData()
        },
    },
}
</script>
<style lang="scss" scoped>
.newsDetails_page {
    .breadcrumb_box {
        background: #f4f5f5;
    }

    .main {
        padding-top: 50px;
        padding-bottom: 50px;

        .richText_box {
            width: 769px;
            padding-right: 40px;

            .title {
                font-size: 24px;
                font-weight: bold;
                color: #333333;
                line-height: 30px;
            }

            .postedTime_viewNum {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #888888;
                margin-top: 30px;

                .postedTime {}

                .viewNum {
                    margin-left: 57px;
                }
            }

            .richText {
                margin-top: 30px;
            }
        }

        .recommend {
            width: 325px;
            height: 1050px;
            border: 1px solid rgba(239, 239, 239, 0.96);
            padding: 27px 20px;
            box-sizing: border-box;

            .recommendNews {
                height: 30px;
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
                line-height: 30px;
                position: relative;
                padding-left: 14px;
                margin-bottom: 2px;

                .line {
                    width: 4px;
                    height: 30px;
                    background: #e62129;
                    position: absolute;
                    top: 0;
                    left: 0;
                    border-radius: 4px;
                }
            }

            .recommendList {
                width: 286px;
                padding-bottom: 30px;
                border-bottom: 1px solid #dedede;
                margin-top: 22px;

                .icon_news {
                    width: 286px;
                    height: 205px;
                    transition: All 0.8s ease;
                    vertical-align: middle;
                }

                .newsName {
                    width: 271px;
                    height: 42px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    line-height: 22px;
                    margin-top: 18px;
                }
            }

            .recommendList:hover {
                .icon_news {
                    transform: scale(1.1);
                    -webkit-transform: scale(1.1);
                }

                .newsName {
                    color: #e62129;
                }
            }
        }
    }
}
</style>